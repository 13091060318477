
const progressCircle = document.querySelector(".autoplay-progress svg");
const progressContent = document.querySelector(".autoplay-progress span");
const homeSlider = new Swiper(".homeSlider", {
	spaceBetween: 30,
	centeredSlides: true,
	autoplay: {
		delay: 10000,
		disableOnInteraction: false
	},
	pagination: {
		el: ".swiper-pagination",
		clickable: true
	},
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev"
	},
	on: {
		autoplayTimeLeft(s, time, progress) {
			progressCircle.style.setProperty("--progress", 1 - progress);
			progressContent.textContent = `${Math.ceil(time / 1000)}s`;
		}
	}
});


const brandSwiperHasata = new Swiper(".brandSwiper", {
	slidesPerView: "auto",
	spaceBetween: 40,
	autoplay: {
		delay: 10000,
		disableOnInteraction: false
	},
	pagination: {
		el: ".swiper-pagination",
		clickable: true,
	},
});


const facilitySwiper = new Swiper(".facilitySwiper", {
	slidesPerView: "auto",
	spaceBetween: 40,
	autoplay: {
		delay: 10000,
		disableOnInteraction: false
	},
	pagination: {
		el: ".swiper-pagination",
		clickable: true,
	},
});


const swiperProductGroup = new Swiper(".swiperProductGroup", {
	slidesPerView: "auto",
	spaceBetween: 0,
	autoplay: {
		delay: 10000,
		disableOnInteraction: false
	},
	pagination: {
		el: ".swiper-pagination",
		clickable: true,
	},
	navigation: {
		nextEl: ".productGroup-button-next",
		prevEl: ".productGroup-button-prev"
	},
});